<template>
  <base-section id="marketing">
    <v-container>
      <v-row>
        <v-col
          cols="3"
          class="text-center hidden-sm-and-down"
        >
          <template v-for="(card, i) in cards">
            <base-avatar-card
              :key="card.title"
              align="center"
              v-bind="card"
            />

            <v-divider
              v-if="i + 1 !== cards.length"
              :key="`divider-${i}`"
              class="my-8"
            />
          </template>
        </v-col>

        <v-col
          cols="2"
          class="hidden-sm-and-down text-center"
        >
          <v-responsive
            height="calc(100% - 16px)"
            class="mt-2"
          >
            <v-divider vertical />
          </v-responsive>
        </v-col>

        <v-col
          cols="12"
          md="7"
        >
          <!-- <base-subheading
            size="display-1"
            title="PAYDONE"
            space="0"
          /> -->
          <base-img
            :src="require(`@/assets/paydone/paydone-${$vuetify.theme.isDark ? 'dark' : 'light'}.png`)"
            contain
            max-width="200"
            width="100%"
          />

          <base-title
            class="primary--text"
            title="Solutions"
            tag="div"
          />

          <base-body>
            {{ $t('sections.marketing.text') }}
          </base-body>

          <v-row>
            <v-col
              v-for="(text, i) in marketing"
              :key="i"
              cols="12"
              md="6"
            >
              <base-list-item :text="text" />
            </v-col>
            <base-btn
              class="mb-12"
              href="https://console.paydone.fr/#/login"
              target="_blank"
            >
              {{ $t('sections.marketing.cta') }}
            </base-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMarketing',

    data: () => ({
    }),
    computed: {
      cards () {
        return [
          {
            title: this.$t('sections.marketing.cards[0].title'),
            icon: 'mdi-credit-card-multiple',
            outlined: true,
            dark: true,
            color: 'primary',
          },
          {
            title: this.$t('sections.marketing.cards[1].title'),
            icon: 'mdi-share-variant-outline',
            outlined: true,
            dark: true,
            color: 'primary',
          },
          {
            title: this.$t('sections.marketing.cards[2].title'),
            color: 'primary',
            dark: true,
            icon: 'mdi-home-group',
          },
        ]
      },
      marketing () {
        return this.$t('sections.marketing.marketing')
      },
    },
  }
</script>
